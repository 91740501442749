var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sb_session-card_type-3",attrs:{"font-size":_vm.parseFontSize(
      _vm.settings.fontSize != undefined
        ? _vm.settings.fontSize
        : _vm.object.settings.fontSize,
    ),"image-position":_vm.object.settings.featuredImagePosition,"image-alignment":_vm.object.settings.featuredImageAlignment,"content-image-ratio":_vm.object.settings.contentImageRatio}},[_c('div',{staticClass:"sb_session-card_type-3_content",style:({ padding: _vm.calculateContentPadding() })},[_c('div',{staticClass:"sb_session-card_type-3_content_inner"},[(_vm.object.content.title)?_c('div',{staticClass:"sb_session-card_type-3_title"},[_vm._v(" "+_vm._s(_vm.object.content.title)+" ")]):_vm._e(),_c('div',{key:_vm.contentRenderKey,staticClass:"sb_session-card_type-3_sentences",class:{
          's_line-numbers': _vm.doShowLineNumbers,
          [_vm.WORD_CONTAINER_TAG]: true,
        },style:({
          columns: _vm.object.settings.columns,
        }),domProps:{"innerHTML":_vm._s(_vm.object.content.sentences)}})])]),(_vm.object.content.featuredImage && _vm.showIllustrations)?_c('div',{staticClass:"sb_session-card_type-3_image"},[_c('img',{staticClass:"sb_session-card_type-3_image_img",attrs:{"src":_vm.object.content.featuredImage,"alt":""}})]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }