var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sb_cards-session_training",class:{
    'sb_card-preview': _vm.previewMode,
    'sb_card-print': _vm.printMode,
    'v_reading-comprehension':
      _vm.doShowReadingComprehension && _vm.readingComprehension,
  }},[_c('sb-cards-session-settings-drawer',{attrs:{"student-id":_vm.studentIds.length === 1 ? _vm.studentIds[0] : undefined},on:{"word-definition-mode-change":function($event){_vm.wordDefinitionMode = $event}},model:{value:(_vm.showSettingsModal),callback:function ($$v) {_vm.showSettingsModal=$$v},expression:"showSettingsModal"}}),(!_vm.printMode)?_c('sb-session-scaled-content',{attrs:{"preview-mode":_vm.previewMode}},[(_vm.previewMode || _vm.fullscreen.toggled)?_c('div',{staticClass:"sb_card-preview_navigation"},[_c('i-button',{directives:[{name:"show",rawName:"v-show",value:(_vm.hasPreviousSlide),expression:"hasPreviousSlide"}],on:{"click":_vm.PREVIEW_previousSlide}},[_vm._v(" vorige ")]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.hasPreviousSlide),expression:"!hasPreviousSlide"}],staticStyle:{"width":"120px"}}),(!(_vm.addFinishRemark ? false : !_vm.hasNextSlide))?_c('i-button',{attrs:{"type":_vm.addFinishRemark && !_vm.hasNextSlide ? 'primary' : undefined},on:{"click":_vm.PREVIEW_nextSlide}},[_vm._v(" "+_vm._s(_vm.addFinishRemark && !_vm.hasNextSlide ? 'Oefening afronden' : 'volgende')+" ")]):_vm._e(),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.addFinishRemark && !_vm.hasNextSlide),expression:"!addFinishRemark && !hasNextSlide"}],staticStyle:{"width":"120px"}})],1):_vm._e(),(_vm.totalSlidesAmount > 0)?[_c('sb-session-practice-card',{style:({
          wordSpacing: _vm.cardCssSettings.wordSpacing,
          letterSpacing: _vm.cardCssSettings.letterSpacing,
          lineHeight: _vm.cardCssSettings.lineHeight,
          fontFamily: _vm.cardCssSettings.fontFamily,
          counterReset: 'training',
        }),attrs:{"current-step":_vm.slideIndex + 1,"total-steps":_vm.totalSlidesAmount,"card-name":_vm.currentCardName},on:{"swipeleft":_vm.nextSlide,"swiperight":_vm.previousSlide},scopedSlots:_vm._u([{key:"bottom-left",fn:function(){return [(_vm.slides[_vm.slideIndex].audioFile)?_c('button',{attrs:{"id":"CardAudioTempoButton","title":"Audio met tempo. De audio van de tekst wordt met een regulier tempo afgespeeld."},on:{"click":function($event){_vm.audioPlaying === _vm.slides[_vm.slideIndex].audioFile
                ? _vm.audioController.pause()
                : _vm.audioController.play(_vm.slides[_vm.slideIndex].audioFile)}}},[_c('sb-button',{attrs:{"button-type":"light-black","icon-size":"large","round":"","ghost":""}},[(_vm.audioPlaying !== _vm.slides[_vm.slideIndex].audioFile)?_c('sb-icon',{attrs:{"icon-id":"icon_audio-fast"}}):_c('icon',{attrs:{"type":"ios-pause","size":20}})],1)],1):_vm._e(),(_vm.slides[_vm.slideIndex].audioFileSlowTrack)?_c('button',{attrs:{"id":"CardAudioSlowButton","title":"Audio wat rustiger. De audio van de tekst wordt met een rustige snelheid afgespeeld."},on:{"click":function($event){_vm.audioPlaying === _vm.slides[_vm.slideIndex].audioFileSlowTrack
                ? _vm.audioController.pause()
                : _vm.audioController.play(_vm.slides[_vm.slideIndex].audioFileSlowTrack)}}},[_c('sb-button',{attrs:{"button-type":"light-black","icon-size":"large","round":"","ghost":""}},[(_vm.audioPlaying !== _vm.slides[_vm.slideIndex].audioFileSlowTrack)?_c('sb-icon',{attrs:{"icon-id":"icon_audio-slow"}}):_c('icon',{attrs:{"type":"ios-pause","size":20}})],1)],1):_vm._e(),(_vm.get(_vm.card, 'instruction'))?_c('button',{on:{"click":_vm.showInstructions}},[_c('sb-button',{attrs:{"id":"CardInfoButton","button-type":"light-black","icon-size":"large","round":"","ghost":""}},[_c('sb-icon',{attrs:{"scale":"1.1","icon-id":"icon_info"}})],1)],1):_vm._e(),(
              !_vm.get(_vm.platformSettings, 'readingComprehension') ||
              !_vm.get(_vm.card, 'addReadingComprehension')
            )?_c('div',{attrs:{"id":"CardReadingComprehensionButton"}}):_vm._e(),(
              _vm.get(_vm.platformSettings, 'readingComprehension') &&
              _vm.get(_vm.card, 'addReadingComprehension')
            )?_c('button',{staticClass:"flex flex-align-center flex-justify-end gap-1",attrs:{"id":"CardReadingComprehensionButton"},on:{"click":function($event){_vm.doShowReadingComprehension = !_vm.doShowReadingComprehension}}},[_c('sb-button',{staticClass:"reading-comprehension-btn",class:{
                s_active: _vm.doShowReadingComprehension,
              },attrs:{"button-type":"light-black","icon-size":"large","round":"","ghost":""}},[_c('sb-icon',{attrs:{"scale":"1.1","icon-id":"icon_reading-comprehension"}})],1)],1):_vm._e()]},proxy:true},{key:"bottom-right",fn:function(){return [(_vm.get(_vm.platformSettings, 'dictionary'))?_c('div',{staticClass:"sb_cards-session_reading-comprehension-button",attrs:{"id":"CardDictionaryButton"}},[_c('div',{staticClass:"flex flex-col gap-1"},[_c('div',{staticClass:"flex flex-align-center flex-justify-end gap-1"},[_vm._v(" Woordenboek "),_c('i-switch',{nativeOn:{"click":function($event){$event.stopPropagation();}},model:{value:(_vm.$store.state.doShowWordDefinitions),callback:function ($$v) {_vm.$set(_vm.$store.state, "doShowWordDefinitions", $$v)},expression:"$store.state.doShowWordDefinitions"}})],1)])]):_vm._e(),_c('button',{on:{"click":function($event){_vm.showSettingsModal = true}}},[_c('sb-button',{attrs:{"id":"CardSettingsButton","button-type":"light-black","icon-size":"large","round":"","ghost":""}},[_c('sb-icon',{attrs:{"icon-id":"icon_cog"}})],1)],1),(_vm.printMode)?_c('div',{attrs:{"id":"CardFullScreenButton"}}):_vm._e(),(!_vm.printMode)?_c('button',{attrs:{"id":"CardFullScreenButton"},on:{"click":_vm.toggleFullScreen}},[_c('sb-button',{attrs:{"button-type":"light-black","icon-size":"large","round":"","ghost":""}},[_c('icon',{attrs:{"type":_vm.fullscreen.toggled ? 'md-contract' : 'md-expand',"size":20}})],1)],1):_vm._e(),_c('button',{on:{"click":_vm.printCard}},[_c('sb-button',{attrs:{"id":"CardPrintButton","button-type":"light-black","icon-size":"large","round":"","ghost":""}},[_c('sb-icon',{attrs:{"icon-id":"icon_print"}})],1)],1)]},proxy:true}],null,false,2308549316)},[_vm._l((_vm.CARDS_AMOUNT),function(number){return [(
              _vm.slides[_vm.slideIndex].content.__typename ===
              'CardSlideContentType' + number
            )?_c('sb-session-card-type' + number,{key:`${number}-${_vm.slideIndex}`,tag:"component",attrs:{"object":_vm.slides[_vm.slideIndex],"all-slides":_vm.slides,"show-illustrations":_vm.settings.showIllustrations}}):_vm._e()]})],2)]:_vm._e()],2):_vm._e(),(
      _vm.get(_vm.platformSettings, 'readingComprehension') &&
      _vm.doShowReadingComprehension &&
      !!_vm.readingComprehension
    )?_c('sb-reading-comprehension-column',{attrs:{"data-print":_vm.isPrintMode},on:{"close":function($event){_vm.doShowReadingComprehension = false}}},[_c('sb-reading-comprehension',{attrs:{"reading-comprehension":_vm.readingComprehension}})],1):_vm._e(),(_vm.printMode)?_c('div',{staticClass:"sb_print-card"},[_c('div',{staticClass:"sb_print-button-container"},[_c('p',[_vm._v(" "+_vm._s(_vm.get(_vm.card, 'title') || 'Kaart ophalen...')+" ")]),_c('i-button',{attrs:{"type":"primary","size":"large","disabled":_vm.loadingPrintContent},on:{"click":_vm.printCard}},[_vm._v(" "+_vm._s(_vm.loadingPrintContent ? 'Voorbereiden...' : 'Kaart afdrukken')+" ")])],1),(_vm.totalSlidesAmount > 0)?_c('div',{staticClass:"sb_print-card_slides"},[_vm._l((_vm.slides),function(slide,printIndex){return _c('sb-session-practice-card',{key:slide.id,style:({
          wordSpacing: _vm.cardCssSettings.wordSpacing,
          letterSpacing: _vm.cardCssSettings.letterSpacing,
          lineHeight: _vm.cardCssSettings.lineHeight,
          fontFamily: _vm.cardCssSettings.fontFamily,
        }),attrs:{"current-step":printIndex + 1,"total-steps":_vm.totalSlidesAmount,"card-name":_vm.getCardNameByIndex(printIndex),"print-mode":""},on:{"swipeleft":_vm.nextSlide,"swiperight":_vm.previousSlide}},[_vm._l((_vm.CARDS_AMOUNT),function(number){return [(
              _vm.slides[printIndex].content.__typename ===
              'CardSlideContentType' + number
            )?_c('sb-session-card-type' + number,{key:`${number}-${printIndex}`,tag:"component",attrs:{"object":_vm.slides[printIndex],"show-illustrations":_vm.settings.showIllustrations}}):_vm._e()]})],2)}),_c('div',{staticClass:"reading-comprehension-print"},[(
            _vm.get(_vm.platformSettings, 'readingComprehension') &&
            _vm.doShowReadingComprehension &&
            !!_vm.readingComprehension
          )?_c('sb-reading-comprehension',{attrs:{"reading-comprehension":_vm.readingComprehension}}):_vm._e()],1),_c('div',{staticClass:"sb_print-spacer"})],2):_vm._e()]):_vm._e(),(!_vm.printMode)?_c('sb-cards-session-meta-modal',{attrs:{"card":_vm.card},model:{value:(_vm.showMetaModal),callback:function ($$v) {_vm.showMetaModal=$$v},expression:"showMetaModal"}}):_vm._e(),(
      _vm.get(_vm.platformSettings, 'readingComprehension') &&
      !_vm.doShowReadingComprehension &&
      !!_vm.readingComprehension
    )?_c('div',{staticClass:"sb_cards-session_reading-comprehension_toggle",on:{"click":function($event){_vm.doShowReadingComprehension = true}}},[_c('sb-icon',{attrs:{"icon-id":"icon_reading-comprehension"}})],1):_vm._e(),(_vm.showCloseButton)?_c('button',{staticClass:"close-button",on:{"click":_vm.handleClose}},[_c('sb-icon',{staticStyle:{"color":"red"},attrs:{"icon-id":"icon_close"}})],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }